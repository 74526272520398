@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  :root {
    --color-ink-0: 255 255 255;
    --color-ink-50: 249 245 249;
    --color-ink-100: 231 223 230;
    --color-ink-200: 206 192 206;
    --color-ink-300: 162 142 164;
    --color-ink-400: 130 109 136;
    --color-ink-500: 96 82 102;
    --color-ink-600: 79 68 85;
    --color-ink-700: 62 54 58;
    --color-ink-800: 46 41 51;
    --color-ink-900: 32 30 37;
    --color-ink-950: 22 20 25;
    --color-ink-1000: 0 0 0;

    --color-canvas-0: 255 255 255;
    --color-canvas-50: 247 238 249;
    --color-canvas-100: 231 223 230;

    --color-primary-50: 253 242 248;
    --color-primary-100: 252 231 243;
    --color-primary-200: 251 207 232;
    --color-primary-300: 249 168 212;
    --color-primary-400: 244 114 182;
    --color-primary-500: 236 72 153;
    --color-primary-600: 219 39 119;
    --color-primary-700: 190 24 93;
    --color-primary-800: 157 23 77;
    --color-primary-900: 157 23 77;
    --color-primary-950: 131 24 67;

    --color-no-50: 255 243 241;
    --color-no-100: 255 235 231;
    --color-no-200: 255 208 194;
    --color-no-300: 255 164 151;
    --color-no-400: 255 124 102;
    --color-no-500: 247 88 54;
    --color-no-600: 239 48 19;
    --color-no-700: 209 30 12;
    --color-no-800: 166 27 10;
    --color-no-900: 132 29 13;
    --color-no-950: 73 15 6;

    --color-yes-50: 240 253 250;
    --color-yes-100: 204 251 241;
    --color-yes-200: 153 246 228;
    --color-yes-300: 94 234 212;
    --color-yes-400: 45 212 191;
    --color-yes-500: 20 184 166;
    --color-yes-600: 13 148 136;
    --color-yes-700: 15 118 110;
    --color-yes-800: 17 94 89;
    --color-yes-900: 19 78 74;
    --color-yes-950: 4 47 46;
  }
  .dark {
    color-scheme: dark;

    --color-ink-1000: 255 255 255;
    --color-ink-950: 249 245 249;
    --color-ink-900: 231 223 230;
    --color-ink-800: 206 192 206;
    --color-ink-700: 162 142 164;
    --color-ink-600: 130 109 136;
    --color-ink-500: 96 82 102;
    --color-ink-400: 79 68 85;
    --color-ink-300: 62 54 58;
    --color-ink-200: 46 41 51;
    --color-ink-100: 32 30 37;
    --color-ink-50: 22 20 25;
    --color-ink-0: 0 0 0;

    --color-canvas-0: 32 30 37;
    --color-canvas-50: 22 20 25;
    --color-canvas-100: 46 41 51;

    --color-primary-950: 253 242 248;
    --color-primary-900: 252 231 243;
    --color-primary-800: 251 207 232;
    --color-primary-700: 249 168 212;
    --color-primary-600: 244 114 182;
    --color-primary-500: 236 72 153;
    --color-primary-400: 219 39 119;
    --color-primary-300: 190 24 93;
    --color-primary-200: 157 23 77;
    --color-primary-100: 157 23 77;
    --color-primary-50: 131 24 67;

    --color-no-950: 255 243 241;
    --color-no-900: 255 235 231;
    --color-no-800: 255 208 194;
    --color-no-700: 255 164 151;
    --color-no-600: 255 124 102;
    --color-no-500: 247 88 54;
    --color-no-400: 239 48 19;
    --color-no-300: 209 30 12;
    --color-no-200: 166 27 10;
    --color-no-100: 132 29 13;
    --color-no-50: 73 15 6;

    --color-yes-950: 240 253 250;
    --color-yes-900: 204 251 241;
    --color-yes-800: 153 246 228;
    --color-yes-700: 94 234 212;
    --color-yes-600: 45 212 191;
    --color-yes-500: 20 184 166;
    --color-yes-400: 13 148 136;
    --color-yes-300: 15 118 110;
    --color-yes-200: 17 94 89;
    --color-yes-100: 19 78 74;
    --color-yes-50: 4 47 46;
  }
}

@font-face {
  font-family: 'emoji';
  src: local('AppleColorEmoji') local('Segoe UI Emoji'),
    local('Noto Color Emoji');
  /* from official unicode range for emoji: https://util.unicode.org/UnicodeJsps/list-unicodeset.jsp?a=%5B%3AEmoji%3DYes%3A%5D%0D%0A&abb=on&esc=on&g=&i= */
  /* but include zero width joiner and variant block selector, like 🏳️‍🌈 */
  unicode-range: U+200D, U+FE0?, U+203C, U+2049, U+2122, U+2139, U+2194-2199,
    U+21A9, U+21AA, U+231A, U+231B, U+2328, U+23CF, U+23E9-23F3, U+23F8-23FA,
    U+24C2, U+25AA, U+25AB, U+25B6, U+25C0, U+25FB-25FE, U+2600-2604, U+260E,
    U+2611, U+2614, U+2615, U+2618, U+261D, U+2620, U+2622, U+2623, U+2626,
    U+262A, U+262E, U+262F, U+2638-263A, U+2640, U+2642, U+2648-2653, U+265F,
    U+2660, U+2663, U+2665, U+2666, U+2668, U+267B, U+267E, U+267F, U+2692-2697,
    U+2699, U+269B, U+269C, U+26A0, U+26A1, U+26A7, U+26AA, U+26AB, U+26B0,
    U+26B1, U+26BD, U+26BE, U+26C4, U+26C5, U+26C8, U+26CE, U+26CF, U+26D1,
    U+26D3, U+26D4, U+26E9, U+26EA, U+26F0-26F5, U+26F7-26FA, U+26FD, U+2702,
    U+2705, U+2708-270D, U+270F, U+2712, U+2714, U+2716, U+271D, U+2721, U+2728,
    U+2733, U+2734, U+2744, U+2747, U+274C, U+274E, U+2753-2755, U+2757, U+2763,
    U+2764, U+2795-2797, U+27A1, U+27B0, U+27BF, U+2934, U+2935, U+2B05-2B07,
    U+2B1B, U+2B1C, U+2B50, U+2B55, U+3030, U+303D, U+3297, U+3299, U+1F004,
    U+1F0CF, U+1F170, U+1F171, U+1F17E, U+1F17F, U+1F18E, U+1F191-1F19A,
    U+1F1E6-1F1FF, U+1F201, U+1F202, U+1F21A, U+1F22F, U+1F232-1F23A, U+1F250,
    U+1F251, U+1F300-1F321, U+1F324-1F393, U+1F396, U+1F397, U+1F399-1F39B,
    U+1F39E-1F3F0, U+1F3F3-1F3F5, U+1F3F7-1F4FD, U+1F4FF-1F53D, U+1F549-1F54E,
    U+1F550-1F567, U+1F56F, U+1F570, U+1F573-1F57A, U+1F587, U+1F58A-1F58D,
    U+1F590, U+1F595, U+1F596, U+1F5A4, U+1F5A5, U+1F5A8, U+1F5B1, U+1F5B2,
    U+1F5BC, U+1F5C2-1F5C4, U+1F5D1-1F5D3, U+1F5DC-1F5DE, U+1F5E1, U+1F5E3,
    U+1F5E8, U+1F5EF, U+1F5F3, U+1F5FA-1F64F, U+1F680-1F6C5, U+1F6CB-1F6D2,
    U+1F6D5-1F6D7, U+1F6DC-1F6E5, U+1F6E9, U+1F6EB, U+1F6EC, U+1F6F0,
    U+1F6F3-1F6FC, U+1F7E0-1F7EB, U+1F7F0, U+1F90C-1F93A, U+1F93C-1F945,
    U+1F947-1F9FF, U+1FA70-1FA7C, U+1FA80-1FA88, U+1FA90-1FABD, U+1FABF-1FAC5,
    U+1FACE-1FADB, U+1FAE0-1FAE8, U+1FAF0-1FAF8;
}

@font-face {
  font-family: 'icomoon';
  src: url('../public/fonts/icomoon.eot?v49ui9#iefix')
      format('embedded-opentype'),
    url('../public/fonts/icomoon.ttf?v49ui9') format('truetype'),
    url('../public/fonts/icomoon.woff?v49ui9') format('woff'),
    url('../public/fonts/icomoon.svg?v49ui9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  unicode-range: U+1E40;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mana_3:before {
  content: '\1e40';
}

/* For Webkit-inkd browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

text {
  font-family: icomoon, var(--font-main), emoji, sans-serif;
}
